import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="March 2021" subnav="release-notes">
      <div id="March2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          While we may have been asleep during the last few months of winter, there are signs of new growth in Uniform.
          Read on for our load of updates and be prepared for even more to come!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.6.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.6.0"
            />

            <div className={styles.changesList}>
              <Added>Intellisense descriptions for all component props.</Added>
              <Added>
                <code>isReadOnly</code> prop to all{' '}
                <Link href="/components/forms/checkbox/code/">Checkbox</Link>
                {' and '}
                <Link href="/components/forms/parent-checkbox/code">
                  ParentCheckbox
                </Link>{' '}
                components.
              </Added>
              <Improved>
                TypeScript support, leading to stricter type checking.
              </Improved>
              <Improved>
                The Storybook{' '}<Link href="https://github.com/hudl/uniform-ui-components/wiki/Storybook">
                  has been updated
                </Link>{' '}to include additional component demos and variations.
              </Improved>
              <Improved>Multiselect <Link href="/components/forms/select/code">Selects</Link>{' '}and CreatableSelects
              to no longer allow tabbing to the clear indicator.</Improved>
              <Improved>
                Build pipelines for faster dev experience and reduced package sizes.
              </Improved>
              <Improved>
                Dependencies for{' '}<Link href="https://github.com/hudl/uniform-ui-components">components</Link>.
              </Improved>
              <Improved>
                Usage guidelines for fixed-size{' '}<Link href="/components/icons/ui-controls/design">UI Control icons</Link>.
              </Improved>
              <Fixed>Icon-only{' '}
                <Link href="/components/buttons/button/code">Buttons</Link>{' '}to remain fixed width.</Fixed>
              <Fixed>The Button TypeScript file.</Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Easy access to the{' '}<Link href="/v3">Version 3 documentation</Link>{' '}to the navigation. (Via Hudl VPN.)
              </Added>
              <Improved><Link href="/components/forms/radio/code#Radio%20Group">Radio Group</Link>{' '}codesnippet
              to include state management.</Improved>
              <Fixed>Some typos in the documentation.</Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Improved>
                The low fidelity library to use autolayout and variants.
              </Improved>
              <Fixed>
                Issue with reaction icon names in the Figma React Native Export
                plugin.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
